.info-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.info-left {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.info-right {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info-input fieldset {
  border: var(--border-main) !important;
}

.info-input * {
  color: var(--text-main) !important;
}

@keyframes slideDown {
  0% {
    height: 0px;
  }

  100% {
    height: 20rem;
  }
}

@keyframes slideUp {
  0% {
    height: 20rem;
  }
  100% {
    height: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .info-settings {
    height: 0;
    overflow-y: hidden;
    flex-direction: column;
    row-gap: 1rem;
  }

  .info-left {
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .info-right {
    justify-content: space-between;
    width: 100%;
  }
}
