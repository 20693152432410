.lb-container {
  height: inherit;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
}

.lb-user {
  align-items: center;
  column-gap: 1rem;
}

.lb-user-img {
  max-height: 2.5rem;
  aspect-ratio: 1;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.lb-box-container {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0%;
  z-index: 89;
  animation: fadeIn 0.05s ease-in-out;
}

.lb-user-box {
  position: fixed;
  top: 5rem;
  right: 1rem;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 1rem;
  z-index: 90;
  row-gap: 1rem;
  box-shadow: var(--shadow-main);
  color: var(--text-main);
}

@media only screen and (max-width: 1024px) {
  .lb-container {
    padding: 0;
  }
}
