body {
  --primary-main: #00cccc;
  --primary-light: #33d6d6;
  --primary-dark: #008e8e;
  --primary-contrast: rgba(0, 0, 0, 0.87);

  --secondary-main: #ff5454;
  --secondary-light: #ff7676;
  --secondary-dark: #b23a3a;
  --secondary-contrast: #ffffff;

  --success-main: #4caf50;
  --success-light: #81c784;
  --success-dark: #388e3c;

  --background: #fff;
  --background-dark: hsl(0, 0%, 98%);
  --background-darker: hsl(0, 0%, 93%);

  --shadow-main: 0px 5px 5px -3px rgba(0, 0, 0, 0.05),
    0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 10px 2px rgb(0 0 0 / 4%);

  --border-main: solid 1px rgba(128, 128, 128, 0.5);

  --text-main: rgba(0, 0, 0, 0.87);
  --text-disabled: rgba(128, 128, 128, 0.5);
  --text-icon: rgb(125, 125, 125);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.App {
  display: flex;
  flex-direction: column;
}

.icon-btn {
  color: var(--text-icon) !important;
}
