.recurrence-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.recurrence-main {
  display: flex;
  white-space: nowrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.recurrence-main fieldset {
  border: var(--border-main) !important;
}

.recurrence-main * {
  color: var(--text-main) !important;
}

.recurrence-week {
  display: flex;
  justify-content: space-between;
  list-style: none;
  gap: 3%;
}

.recurrence-week li {
  width: 100%;
}

.recurrence-week li,
.recurrence-month li {
  background-color: var(--background-dark);
  border: var(--border-main);
  color: var(--text-main);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 10rem;
  cursor: pointer;
  font-size: 0.8rem;
}

.recurrence-week li:hover,
.recurrence-month li:hover {
  background-color: var(--background-darker);
}

.pattern-selected {
  color: var(--primary-main) !important;
  border-color: var(--primary-main) !important;
  font-weight: 700;
}

.recurrence-month {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  row-gap: 1rem;
  column-gap: 2%;
}

.recurrence-month li {
  width: 8.2%;
}
