.goal-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.mobile-toggle {
  display: none;
  background-color: var(--background-dark);
}

@media only screen and (max-width: 768px) {
  .goal-container {
    height: fit-content;
    flex-direction: column;
  }

  .mobile-toggle {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
