.polka-container {
    position: absolute;
    bottom: 10%;
    right: 0%;
    margin: 15px;
    background-color: #fafafa;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 12px;
    padding: 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    animation: slideIn 1.5s ease-in-out;
}

@keyframes slideIn {
    0% {
        transform: translateX(120%);
    }

    50% {
        transform: translateX(120%);
    }

    100% {
        transform: translateX(0%);
    }
}

.polka-title {
    color:rgb(3, 25, 34);
    font-size: 18px;
    font-weight: 500;
}

.polka-subtitle {
    color: rgb(120, 128, 128);
    font-size: 14px;
    font-weight: 400;
}