.info-container {
  width: 20%;
  background-color: var(--background-dark);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--text-main);
}

@media only screen and (max-width: 768px) {
  .info-container {
    width: 100%;
    border-bottom: var(--border-main);
  }
}
