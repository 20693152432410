.lo-container {
  background-color: var(--primary-main);
  height: 93%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
}

.lo-title {
  font-size: 4rem;
}

.lo-subtitle {
  font-size: 1.5rem;
}

.lo-left {
  color: white;
  width: 40%;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.lo-right {
  width: 25%;
}

.lo-calendar {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .lo-container {
    flex-direction: column;
    padding: 2rem 1.5rem;
    row-gap: 1rem;
    justify-content: flex-start;
    height: fit-content;
  }

  .lo-left {
    padding: 0;
    width: 100%;
  }

  .lo-right {
    width: 100%;
  }
}
