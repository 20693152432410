.tutorial-container {
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  animation: fadeIn 0.05s ease-in-out;
}

.tutorial {
  width: 40%;
  border-radius: 1rem;
  box-shadow: var(--shadow-main);
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  padding: 3rem;
  line-height: 2rem;
}

@media only screen and (max-width: 480px) {
  .tutorial {
    height: 90%;
    width: 90%;
    overflow-y: auto;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .tutorial {
    max-height: 90%;
    width: 80%;
    overflow-y: auto;
    justify-content: flex-start;
  }
}
