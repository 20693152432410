.prompt-container {
  position: fixed;
  left: 0%;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.05s ease-in-out;
}

.prompt {
  position: relative;
  height: max-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: var(--shadow-main);
  border-radius: 1rem;
  padding: 1.5rem;
  background-color: var(--background);
}

.prompt-content {
  display: flex;
  column-gap: 1rem;
  margin-top: 1rem;
}
