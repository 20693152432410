.calendars-container {
  width: 80%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  background-color: var(--background-dark);
}

.calendars {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 12px;
  padding: 0 1rem;
}

.calendar {
  width: 23%;
  margin: 1%;
  border: var(--border-main);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: var(--background);
}

.selected {
  background-color: var(--secondary-main) !important;
  color: white !important;
}

.selected:hover {
  background-color: var(--secondary-light) !important;
}

.tile {
  border: 0.2rem solid var(--background);
  background-color: var(--background);
  cursor: pointer;
  border-radius: 50%;
  aspect-ratio: 1;
  padding: 0.2rem;
  color: var(--text-main);
}

.tile:disabled {
  color: var(--text-disabled);
}

.tile:hover {
  background-color: var(--background-dark);
}

.react-calendar__month-view__weekdays div {
  display: flex;
  justify-content: center;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none;
  color: gray;
}

.today {
  border: 0.15rem solid grey !important;
}

.calendar-label {
  font-size: 20px;
  padding: 0.5rem;
  color: var(--text-main);
}

.MuiPaper-root {
  background-color: var(--background) !important;
}

.MuiPaper-root * {
  color: var(--text-main) !important;
}

.MuiPaper-rounded {
  border-radius: 0.5rem !important;
}

.start-end {
  border: 0.15rem solid var(--primary-main) !important;
}

@media only screen and (max-width: 480px) {
  .calendars-container {
    padding: 1rem 0;
    height: fit-content;
    width: 100%;
  }

  .calendars {
    flex-direction: column;
    width: 100%;
    row-gap: 1rem;
    height: fit-content;
  }

  .calendar {
    width: 98%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 481px) {
  .calendars-container {
    padding: 1rem 0;
    height: fit-content;
    width: 100%;
  }

  .calendars {
    width: 100%;
  }

  .calendar {
    width: 48%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .calendar {
    width: 31%;
  }
}
