.footer-container {
  min-height: 7%;
  width: 100%;
  background: var(--background-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-main);
  padding: 1rem 2rem;
  flex-wrap: wrap;
}

.footer-content {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.footer-logo {
  width: 4rem;
}

.footer-link {
  color: var(--secondary-main);
}

.footer-dark-switch {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-version {
  font-size: small;
  color: lightgrey;
}

@media only screen and (max-width: 768px) {
  .footer-container {
    min-height: fit-content;
  }

  .footer-content {
    height: fit-content;
  }

  .footer-info,
  .footer-name {
    display: none;
  }
}
