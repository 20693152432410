.progress-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--primary-contrast);
  width: 100%;
  border: var(--border-main);
  border-radius: 0.25rem;
  padding: 0.4rem 0.8rem;
  color: var(--text-main);
}

.progress-title {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.progress-section {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.progress-bar {
  width: 100%;
}

.divider {
  margin-top: 0.5rem;
  border-top: var(--border-main);
}

.progress-total {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .progress-container {
    flex-direction: column;
    row-gap: 0.3rem;
  }

  .progress-section {
    width: 100%;
  }
}
