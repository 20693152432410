.li-container {
  height: 93%;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}

.li-content {
  width: 100%;
  overflow: hidden;
  height: calc(100% - 5.5rem);
}

.nav-bar {
  width: 100%;
  height: 5.5rem;
  background-color: var(--background-dark);
  overflow: hidden;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 10% 80% auto;
}

.li-logo-container {
  height: inherit;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.li-logo {
  max-width: 100%;
  max-height: 100%;
}

.tablist {
  height: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  column-gap: 1rem;
}

.tablist::-webkit-scrollbar {
  display: none;
}

.tab {
  min-width: fit-content !important;
  white-space: nowrap;
  font-size: 1rem !important;
  transition: 0.25s;
  font-weight: 700 !important;
  color: var(--primary-dark);
}

.li-add {
  margin: 1rem 3rem 1rem 1rem;
}

.shadow-div {
  position: sticky;
  right: 0;
  height: 100%;
  box-shadow: 0 0 20px 25px var(--background-dark);
  z-index: 1;
}

.nav-user {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .li-container {
    height: fit-content;
    overflow-y: visible;
  }

  .li-content {
    height: fit-content;
    overflow: visible;
  }

  .nav-bar {
    padding: 0 1rem;
    grid-template-columns: 80% auto;
  }

  .li-logo-container {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav-bar {
    padding: 0 1rem;
  }
}
